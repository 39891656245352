
export const range = (start, end) => [...Array(1+end-start).keys()].map(v => start+v);

export const friendlyMoney = inCents => {
    if (inCents === null || typeof inCents === 'undefined' || Number.isNaN(inCents)) {
        return;
    }

    const inEuro = inCents / 100;

    if (Number.isNaN(inEuro)) {
        return;
    }

    const locale = 'en-IE';
    const formatOptions = { style: 'currency', currency: 'EUR' };

    return new Intl.NumberFormat(locale, formatOptions).format(inEuro);
}
