<template>
  <div class="has-background-light main-container">
    <header>
      <nav class="navbar is-dark has-text-light normal-header">
        <a class="navbar-item has-text-light ml-4" @click="$router.push({ name: 'home'})">
          <strong>Entry Table</strong>
        </a>
        <span class="navbar-end mr-4">
          <a class="navbar-item" @click="goToBasket">
            <span v-if="basketCount > 0" class="basketCount">
              {{ basketCount }}
            </span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 18H4V8H8V10H10V8H14V10H16V8H20V18ZM14 6H10C10 4.89717 10.8972 4 12 4C13.1028 4 14 4.89717 14 6ZM16 6C16 3.79442 14.2056 2 12 2C9.79442 2 8 3.79442 8 6H2V19L3 20H21L22 19V6H16Z" fill="#ffffff"/>
            </svg>
          </a>
        </span>
      </nav>
    </header>
    <section class="hero is-dark-one details-hero"
             :style="headerImage ? `background-image: url(${headerImage});` : ''">
      <div class="hero-body">
        <div class="container has-text-white">
          <div class="columns">
            <div class="is-one-third is-offset-one-third column">
              <h2 class="title is-2">{{ feis.name }}</h2>
              <p class="is-6 mb-5">{{ feis.type }}</p>
              <p><strong v-html="formattedDates" /></p>
              <hr/>
              <div class="columns">
                <div class="column">
                  <ul>
                    <li v-html="multiline(feis.venue)"/>
                  </ul>
                </div>
                <div class="column">
                  <ul>
                    <li class="mb-5">Closing date for entry<br/> {{ friendlyDateTime(feis.closing_date) }} </li>
                    <li><a v-if="feis.syllabus" class="same-color" :href="feis.syllabus.path">Download syllabus
                      <svg class="ml-2" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7 6.66875C11.9423 6.91252 11.9423 7.30623 11.7 7.55L8.35 10.9125C8.12567 11.14 7.8195 11.2681 7.5 11.2681C7.1805 11.2681 6.87433 11.14 6.65 10.9125L3.3 7.56875C3.14258 7.41021 3.0817 7.17974 3.14028 6.96414C3.19887 6.74854 3.36803 6.58058 3.58403 6.52351C3.80004 6.46645 4.03008 6.52896 4.1875 6.6875L6.875 9.375V1.875C6.875 1.52982 7.15482 1.25 7.5 1.25C7.84518 1.25 8.125 1.52982 8.125 1.875V9.40625L10.8125 6.66875C10.9299 6.55043 11.0896 6.48388 11.2563 6.48388C11.4229 6.48388 11.5826 6.55043 11.7 6.66875ZM12.5 12V10C12.5 9.65482 12.7798 9.375 13.125 9.375C13.4702 9.375 13.75 9.65482 13.75 10V12C13.7466 12.9651 12.9651 13.7466 12 13.75H3C2.03493 13.7466 1.25343 12.9651 1.25 12V10C1.25 9.65482 1.52982 9.375 1.875 9.375C2.22018 9.375 2.5 9.65482 2.5 10V12C2.5 12.2761 2.72386 12.5 3 12.5H12C12.2761 12.5 12.5 12.2761 12.5 12Z" fill="black"/>
                        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="1" width="13" height="13">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7 6.66875C11.9423 6.91252 11.9423 7.30623 11.7 7.55L8.35 10.9125C8.12567 11.14 7.8195 11.2681 7.5 11.2681C7.1805 11.2681 6.87433 11.14 6.65 10.9125L3.3 7.56875C3.14258 7.41021 3.0817 7.17974 3.14028 6.96414C3.19887 6.74854 3.36803 6.58058 3.58403 6.52351C3.80004 6.46645 4.03008 6.52896 4.1875 6.6875L6.875 9.375V1.875C6.875 1.52982 7.15482 1.25 7.5 1.25C7.84518 1.25 8.125 1.52982 8.125 1.875V9.40625L10.8125 6.66875C10.9299 6.55043 11.0896 6.48388 11.2563 6.48388C11.4229 6.48388 11.5826 6.55043 11.7 6.66875ZM12.5 12V10C12.5 9.65482 12.7798 9.375 13.125 9.375C13.4702 9.375 13.75 9.65482 13.75 10V12C13.7466 12.9651 12.9651 13.7466 12 13.75H3C2.03493 13.7466 1.25343 12.9651 1.25 12V10C1.25 9.65482 1.52982 9.375 1.875 9.375C2.22018 9.375 2.5 9.65482 2.5 10V12C2.5 12.2761 2.72386 12.5 3 12.5H12C12.2761 12.5 12.5 12.2761 12.5 12Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask0)">
                          <rect width="15" height="15" fill="white"/>
                        </g>
                      </svg>
                    </a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <transition name="fade" mode="out-in">
      <div class="section select-competitions">
        <main role="main" class="container is-widescreen">
          <h2 class="title is-3 has-text-dark-one has-text-weight-normal" style="margin-bottom: 56px">
            {{showAllCompetitions ? 'All competitions' : 'Competitions'}} for
            <b-select v-model="currentParticipant" placeholder="Select a name" @input="changeParticipant" class="participant-dropdown">
              <option :value="currentParticipant">{{currentParticipant.name}}</option>
              <option
                v-for="participant in participants"
                :value="participant"
                :key="participant.id">
                {{ participant.name }}
              </option>
              <option :value="currentParticipant">
                Add another dancer
              </option>
            </b-select>
          </h2>
          <div class="border-left-box">
            <h3 class="title is-5 mb-1 has-text-dark-one">{{ currentParticipant.name }}</h3>
            <ul class="mb-4">
              <li>Year of birth &mdash;{{ currentParticipant.year_of_birth }}</li>
              <li>{{ currentParticipant.dancing_school }}</li>
            </ul>
            <a type="is-link is-light" @click="$emit('go-to', 'participant-details')">Edit details</a>
          </div>
          <div style="margin-top: 70px;" v-if="eligibleCompetitions.length > 0">
            <p v-if="showAllCompetitions">All competitions in feis</p>
            <p v-else>{{ eligibleCompetitions.length }} {{ eligibleCompetitions.length === 1 ? 'competition' : 'competitions' }}</p>
            <b-table
              class="mt-4"
              :mobile-cards="false"
              :data="eligibleCompetitions"
              :bordered="false"
              :striped="true">


              <b-table-column field="name" label="Competition" v-slot="props">
                <p><strong>{{ props.row.name }}</strong></p>
                <p>{{ props.row.description }}</p>
                <template v-if="props.row.dance_question == 'heavy_dance'">
                  <p class="mt-2">
                    What Heavy Dance are you doing?
                  </p>
                  <p v-if="currentParticipant.competitions.find(c => c.id === props.row.id)"><em>{{ currentParticipant.competitions.find(c => c.id === props.row.id).dance_choice}}</em></p>
                  <div class="select mt-2">
                    <form :name="`choose-dance-${props.row.id}`">
                      <select :name="`heavy-dance-${props.row.id}`" v-model="props.row.dance_choice" :id="'dance-choice-' + props.row.id">
                        <option value="">Please select</option>
                        <option value="Treble Jig">Treble Jig</option>
                        <option value="Hornpipe">Hornpipe</option>
                      </select>
                    </form>
                  </div>
                </template>
                <template v-else-if="props.row.dance_question == 'traditional_set'">
                  <p class="mt-2">
                    What Traditional Set Dance are you doing?
                  </p>
                  <p v-if="currentParticipant.competitions.find(c => c.id === props.row.id)"><em>{{ currentParticipant.competitions.find(c => c.id === props.row.id).dance_choice}}</em></p>
                  <div class="select mt-2">
                    <form :name="`choose-dance-${props.row.id}`">
                      <select :name="`trad-set-${props.row.id}`" v-model="props.row.dance_choice" :id="'dance-choice-' + props.row.id">
                        <option value="">Please select</option>
                        <option value="St. Patrick’s Day">St. Patrick’s Day</option>
                        <option value="Garden of Daisies">Garden of Daisies</option>
                        <option value="Job of Journeywork">Job of Journeywork</option>
                        <option value="The Blackbird">The Blackbird</option>
                        <option value="King of the Fairies">King of the Fairies</option>
                        <option value="Jockey to the Fair">Jockey to the Fair</option>
                        <option value="Three Sea Captains">Three Sea Captains</option>
                      </select>
                    </form>
                  </div>
                </template>


              </b-table-column>

              <b-table-column field="grade" label="Grade" v-slot="props" width="15em">
                {{ props.row.grade }}
              </b-table-column>

              <b-table-column field="age" label="Age" v-slot="props" width="15em">
                {{ formatDisplayAge(props.row) }}
              </b-table-column>


              <b-table-column field="price" label="Price" v-slot="props" width="10em">
                {{ friendlyMoney(props.row.fee_in_cents) }}
              </b-table-column>

              <b-table-column field="cta" label="" centered v-slot="props" width="272px">
                <b-button v-if="currentParticipant.competitions.map(c => c.id).includes(props.row.id)"
                          type="is-text is-rounded has-text-primary"
                          @click="removeCompetitionFromCurrentParticipant(props.row)" >
                  Remove competition
                </b-button>
                <b-button v-else-if="props.row.entries_left > 0"
                          type="is-link is-light is-primary is-rounded"
                          @click="addCompetition(props.row)" >
                  Enter this competition
                </b-button>
                <p v-else>Competition full. Contact Us to be added to the waiting list.</p>
              </b-table-column>
            </b-table>
          </div>
          <div style="margin-top: 70px;" v-else>
              <div class="columns is-mobile">
                <div class="column is-half">
                  <div class="media">
                    <div class="media-content">
                      <h3 class="title has-text-dark-one is-4">No competitions found</h3>
                      <p class="subtitle is-6 has-text-dark">Please double-check that your details above are correct (e.g. Year of Birth).</p>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div style="margin-top: 28px;">
            <b-button outlined type="is-text"
                      class="view-all-button"
                      @click="showAllCompetitions = !showAllCompetitions">
              <template v-if="showAllCompetitions">
                View competitions
              </template>
              <template v-else>
                View all competitions in feis
              </template>
            </b-button>
          </div>
          <div style="margin-top: 56px">
              <b-button outlined type="is-primary"
                        class="add-dancer-button"
                        icon-right="plus"
                        @click="addAnotherParticipant">Add new dancer</b-button>
            <b-button type="is-primary is-rounded mr-6"
                      style="float: right"
                      :disabled="this.currentParticipant.competitions.length === 0"
                      @click="goToBasket">Go to basket</b-button>
          </div>
        </main>
      </div>
    </transition>
  </div>
</template>

<script>
    import {formatMaxAge, friendlyDate, friendlyDateTime} from './date-time';
    import {friendlyMoney, range} from './numbers';
    import axios from "axios";
    import {loadStripe} from '@stripe/stripe-js';
    // TODO: Move API key so loaded via AJAX
    const stripePromise = loadStripe(window.pk);

    export default {
        props: {
            headerImage: {
                required: false,
                type: String,
                default: undefined
            },
            feis: {
                required: false,
                type: Object,
                default: undefined
            },
            dancingSchools: {
                required: true,
                type: Array
            },
            basket: {
                required: false,
                type: Object
            },
            participants: {
                required: true,
                type: Array
            },
            currentParticipant: {
                required: false,
                type: Object,
                default: undefined
            }
        },
        beforeRouteUpdate(to, from, next) {
            console.log(`Entering ${to.params.name} from ${from.params.name}`);
            if (!this.currentParticipant.name || this.currentParticipant.name.trim() === '') {
                this.$emit('pop-participant');
            }
            next();
        },
        data() {
            return {
                showAllCompetitions: false,
                participantDetailsForm: {
                    hasBeenSubmitted: false,
                    name: {
                        type: undefined,
                        message: undefined
                    },
                    yob: {
                        type: undefined,
                        message: undefined
                    },
                    school: {
                        type: undefined,
                        message: undefined
                    },
                    gender: {
                        type: undefined,
                        message: undefined
                    }
                },
                contactDetailsForm: {
                    hasBeenSubmitted: false,
                    isAdult: {
                        type: undefined,
                        message: undefined
                    },
                    name: {
                        type: undefined,
                        message: undefined
                    },
                    phone: {
                        type: undefined,
                        message: undefined
                    },
                    email: {
                        type: undefined,
                        message: undefined
                    },
                    privacy: {
                        type: undefined
                    }
                },
                notification: undefined,
                hasAcknowledgedPrivacyPolicy: false,
                currentParticipantOldYearOfBirth: undefined,
                accompanyingFullPriceAdults: 0,
                accompanyingConcessionAdults: 0,
                accompanyingChildren: 0,
                isParticipantValid: false,
                contact: {
                    name: null,
                    phone: null,
                    email: null,
                    isAdult: null
                }
            }
        },
        mounted() {

            if (!this.feis) {
                console.log('No feis selected, going to home');
                this.$router.replace({name: 'home'});
                return;
            }

            if (this.basket) {
                this.contact = this.basket.contact;
                this.accompanyingFullPriceAdults = this.basket.number_of_adult_tickets;
                this.accompanyingConcessionAdults = this.basket.number_of_concession_tickets;
                this.accompanyingChildren = this.basket.num

                axios.post('basket/empty').catch(e => console.error(e));
            }
        },
        computed: {
            eligibleCompetitions() {
                const eligibleCompetitions = this.showAllCompetitions
                                                ? this.feis.competitions
                                                : this.feis.competitions
                                                      .filter(competition => new Date(competition.max_age).getFullYear() <= this.currentParticipant.year_of_birth)
                                                      .filter(competition => !competition.min_age || new Date(competition.min_age).getFullYear() >= this.currentParticipant.year_of_birth)
                                                      .filter(competition => competition.gender === 'either' || competition.gender === this.currentParticipant.gender);


                return eligibleCompetitions
                        .map((competition,index) => ({
                          ...competition,
                            index: index
                        }))
                        .sort((a, b) => a.display_index < b.display_index ? -1 : 1)
            },
            isPaymentDisabled() {
                if (this.competitionsSubtotal === 0) {
                    return true;
                }

                if (!this.hasValidNumberOFAccompanyingAdults) {
                    return true;
                }

                return false;
            },
            guestsErrorMessage() {
                if (this.hasValidNumberOFAccompanyingAdults) {
                    return;
                }

                return `This feis allows a maximum of ${this.feis.max_accompanying_per_entrant} accompanying adults per entrant. You currently have ${this.accompanyingConcessionAdults + this.accompanyingFullPriceAdults} added (${this.accompanyingFullPriceAdults} full priced tickets and ${this.accompanyingConcessionAdults} concessions).`
            },
            filteredDancingSchools() {

                const participantsSchool = this.currentParticipant.dancing_school;

                if (!participantsSchool) {
                    return this.dancingSchools;
                }

                // cf. https://stackoverflow.com/a/25575009/1474421
                const punctuationRegex = /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-.\/:;<=>?@\[\]^_`{|}~]/g;
                const spacesRegex = /\s+/g;

                const normalised = word => word.toLowerCase()
                                               .replace(punctuationRegex, '')
                                               .replace(spacesRegex, ' ');

                return this.dancingSchools
                          .filter(school => typeof school !== 'undefined' && school !== null)
                          .filter(school => {
                            const availableSchool = normalised(school);
                            const chosenSchool = normalised(participantsSchool);
                            return availableSchool.includes(chosenSchool);
                          });
            },
            basketCount() {
                const competitionsForCurrentParticipant = this.currentParticipant.competitions.length;
                const countOfOtherParticipantsCompetitions = this.participants
                                                                 .reduce((subtotal, participant) => {
                                                                     return subtotal + participant.competitions.length;
                                                                 }, 0);
                return competitionsForCurrentParticipant + countOfOtherParticipantsCompetitions;
            },
            competitionsSubtotal() {
                return this.participants.reduce((participantSubtotal, participant) => {
                    return participantSubtotal + participant.competitions
                        .reduce((competitionsTotal, competition) => competitionsTotal + competition.fee_in_cents, 0);
                }, 0);
            },
            adultsSubtotal(){
               return this.accompanyingFullPriceAdults * this.feis.admission_fee_cents;
            },
            concessionSubtotal() {
                return this.accompanyingConcessionAdults * this.feis.concession_fee_cents;
            },
            childSubtotal() {
                return this.accompanyingChildren * this.feis.child_fee_cents;
            },
            accompanyingSubtotal() {
                return this.adultsSubtotal + this.concessionSubtotal + this.childSubtotal;
            },
            serviceChargeSubtotal() {
                return this.competitionsSubtotal === 0 ? 0 : this.feis.service_charge_in_cents;
            },
            total() {
                return this.competitionsSubtotal + this.accompanyingSubtotal + this.serviceChargeSubtotal;
            },
            hasValidContactDetails() {

                if (!this.feis.show_contact_details) {
                    this.contact.name = null;
                    this.contact.email = null;
                    this.contact.phone = null;
                    this.contact.isAdult = false;
                    return true;
                }

                if (this.contact.isAdult && this.accompanyingFullPriceAdults === 0 && this.accompanyingConcessionAdults === 0) {
                    return false;
                }

                return this.contact.name != null && this.contact.name.trim() !== '' &&
                    this.contact.phone != null && this.contact.phone.trim() !== '' &&
                    this.contact.email != null && this.contact.email.trim() !== '' &&
                    this.contact.isAdult != null && this.contact.isAdult !== '';
            },
            hasValidNumberOFAccompanyingAdults() {
                const totalRequested = this.accompanyingConcessionAdults + this.accompanyingFullPriceAdults;
                return totalRequested <= (this.feis.max_accompanying_per_entrant * this.participants.length);


            },
            yearsToShown() {
                const currentYear = new Date().getFullYear();
                const youngestAge = 3;
                const oldestAge = 21;
                const ages = range(youngestAge, oldestAge);
                return ages.map(age => currentYear - age);
            },
            formattedDates() {
                if (this.feis.start_date === this.feis.end_date) {
                    // Single day event
                    return `${friendlyDate(this.feis.start_date)}`;
                } else {
                    return `${friendlyDate(this.feis.start_date)} — ${friendlyDate(this.feis.end_date)}`;
                }
            }
        },
        methods: {
            validate(value, field, hasBeenSubmitted) {
                let isValid = false;

                if (field === 'contact-is-adult') {
                    if (typeof value !== 'undefined' && value !== null) {
                        this.contactDetailsForm.isAdult.type = undefined;
                        this.contactDetailsForm.isAdult.message = undefined;
                        return;
                    }
                }

                if (value && String(value).trim() !== '') {
                    isValid = true;
                }

                if (!hasBeenSubmitted) {
                    return;
                }

                if (isValid) {
                    switch (field) {
                        case 'name':
                            this.participantDetailsForm.name.type = undefined;
                            this.participantDetailsForm.name.message = undefined;
                            break;
                        case 'yob':
                            this.participantDetailsForm.yob.type = undefined;
                            this.participantDetailsForm.yob.message = undefined;
                            break;
                        case 'school':
                            this.participantDetailsForm.school.type = undefined;
                            this.participantDetailsForm.school.message = undefined;
                            break;
                        case 'gender':
                            this.participantDetailsForm.gender.type = undefined;
                            this.participantDetailsForm.gender.message = undefined;
                            break;
                        case 'contact-name':
                            this.contactDetailsForm.name.type = undefined;
                            this.contactDetailsForm.name.message = undefined;
                            break;
                        case 'contact-phone':
                            this.contactDetailsForm.phone.type = undefined;
                            this.contactDetailsForm.phone.message = undefined;
                            break;
                        case 'contact-email':
                            this.contactDetailsForm.email.type = undefined;
                            this.contactDetailsForm.email.message = undefined;
                            break;
                        case 'contact-privacy':
                            this.contactDetailsForm.privacy.type = undefined;
                            break;
                    }
                } else {
                    switch (field) {
                        case 'name':
                            this.participantDetailsForm.name.type = 'is-danger';
                            this.participantDetailsForm.name.message = 'Dancer name is required';
                            break;
                        case 'yob':
                            this.participantDetailsForm.yob.type = 'is-danger';
                            this.participantDetailsForm.yob.message = 'Year of birth is required';
                            break;
                        case 'school':
                            this.participantDetailsForm.school.type = 'is-danger';
                            this.participantDetailsForm.school.message = 'Dancing school is required';
                            break;
                        case 'gender':
                            this.participantDetailsForm.gender.type = 'is-danger';
                            this.participantDetailsForm.gender.message = 'Gender is required';
                        case 'contact-is-adult':
                            this.contactDetailsForm.isAdult.type = 'is-danger';
                            this.contactDetailsForm.isAdult.message = 'Please select whose contact details you are providing';
                            break;
                        case 'contact-name':
                            this.contactDetailsForm.name.type = 'is-danger';
                            this.contactDetailsForm.name.message = 'Contact name is required'
                            break;
                        case 'contact-phone':
                            this.contactDetailsForm.phone.type = 'is-danger';
                            this.contactDetailsForm.phone.message = 'Contact phone number is required';
                            break;
                        case 'contact-email':
                            this.contactDetailsForm.email.type = 'is-danger';
                            this.contactDetailsForm.email.message = 'Contact email address is required';
                            break;
                        case 'contact-privacy':
                            this.contactDetailsForm.privacy.type = 'is-danger';
                            break;
                    }
                }
            },
            friendlyDateTime(time) {
              return friendlyDateTime(time);
            },
            changeParticipant(selectedParticipant) {
                if (selectedParticipant == this.currentParticipant) {
                    this.addAnotherParticipant();
                } else {
                    this.participants.push(this.currentParticipant);
                    this.editParticipantCompetitions(selectedParticipant);
                }
            },
            multiline(string) {
              return string.split(/\n/).join('<br/>');
            },
            removeFeis() {
                this.$buefy.dialog.confirm({
                    title: `Remove all participants`,
                    message: `Are you sure you want to remove all participants for ${this.feis.name}?`,
                    confirmText: `Yes, remove all participants`,
                    cancelText: `Cancel`,
                    type: 'is-warning',
                    hasIcon: false,
                    onConfirm: () => {
                        this.loadingSpinner = this.$buefy.loading.open();
                        this.$emit('edit-current-participant', undefined);
                        this.$emit('update-participants', []);
                        axios.post('basket/empty')
                            .then(async response => {
                                this.$emit('back');

                            })
                            .catch(e => {
                                console.error(e);
                                this.loadingSpinner.close();
                                this.$buefy.dialog.alert(`<p>Error clearing basket. If this problem persists, please <a href="/contact" target="_blank">contact us</a> via our support form.</p>`)
                            })
                            .finally(() => this.loadingSpinner.close());
                    }
                });
            },
            captureExistingYearOfBirth() {
                if (this.currentParticipant.competitions.length > 0) {
                    this.currentParticipantOldYearOfBirth = this.currentParticipant.year_of_birth;
                }
            },
            async submitBasket() {
              this.contactDetailsForm.hasBeenSubmitted = true;
              if (!this.hasAcknowledgedPrivacyPolicy) {
                  this.validate(this.contact.privacy, 'contact-privacy', this.contactDetailsForm.hasBeenSubmitted);
              }

              if (!this.hasValidContactDetails) {
                  document.querySelector('#contact_details').scrollIntoView({
                      behavior: 'smooth'
                  });
                  this.validate(this.contact.isAdult, 'contact-is-adult', this.contactDetailsForm.hasBeenSubmitted)
                  this.validate(this.contact.name, 'contact-name', this.contactDetailsForm.hasBeenSubmitted)
                  this.validate(this.contact.phone, 'contact-phone', this.contactDetailsForm.hasBeenSubmitted)
                  this.validate(this.contact.email, 'contact-email', this.contactDetailsForm.hasBeenSubmitted)

                  if (this.contact.isAdult && this.accompanyingFullPriceAdults === 0 && this.accompanyingConcessionAdults === 0) {
                      this.$buefy.dialog.alert({
                          title: 'No adults attending',
                          message: 'You\'ve entered contact details for an accompanying adult, but haven\'t added any tickets to the basket',
                      });

                      return;
                  }
              }

              if (!this.hasAcknowledgedPrivacyPolicy || !this.hasValidContactDetails) {
                  return
              }

              // Should already be zero
              if (!this.show_adult_tickets) {
                  this.accompanyingFullPriceAdults = 0;
              }

              if (!this.show_concession_tickets) {
                  this.accompanyingConcessionAdults = 0;
              }

              if (!this.show_child_tickets) {
                  this.accompanyingChildren = 0;
              }

              this.loadingSpinner = this.$buefy.loading.open();
              const stripe = await stripePromise;
              const payload = {
                  feis_id: this.feis.id,
                  participants: this.participants,
                  number_adult_tickets: this.accompanyingFullPriceAdults,
                  number_concession_tickets: this.accompanyingConcessionAdults,
                  number_child_tickets: this.accompanyingChildren,
                  contact: this.contact
              };

              this.contactDetailsForm.hasBeenSubmitted = false;
              axios.post('basket', payload)
                  .then(async response => {
                      this.loadingSpinner.close();
                      const session = response.data;
                      // When the customer clicks on the button, redirect them to Checkout.
                      const result = await stripe.redirectToCheckout({
                          sessionId: session.id,
                      });

                      if (result.error) {
                          this.$buefy.snackbar.open(`Error loading checkout`)
                      }

                  })
                  .catch(e => {
                      console.error(e);
                      this.loadingSpinner.close();
                      this.$buefy.dialog.alert(`<p>Error saving basket. If this problem persists, please <a href="/contact" target="_blank">contact us</a> via our support form.</p>`)
                  });
            },
            editParticipantCompetitions(participant) {
                this.$emit('update-participants', this.participants.filter(p => p != participant));
                this.$emit('edit-current-participant', participant);
                this.$emit('go-to', 'select-competitions');
            },
            editParticipantDetails(participant) {
              this.$emit('update-participants', this.participants.filter(p => p != participant));
                this.$emit('edit-current-participant', participant);
              this.$emit('go-to', 'participant-details');
            },
            removeParticipant(participant) {
                this.$buefy.dialog.confirm({
                    title: `Removing ${participant.name}`,
                    message: `Are you sure you want to remove ${participant.name}? This will also remove all the competitions that they have entered.`,
                    confirmText: `Yes, remove`,
                    cancelText: `Cancel`,
                    type: 'is-warning',
                    hasIcon: false,
                    onConfirm: () => {
                        participant.competitions.forEach(competition => {
                            this.removeCompetitionWithoutPrompt(competition, participant);
                        });

                        this.$emit('update-participants', this.participants.filter(p => p != participant));
                    }
                });
            },
            removeCompetitionWithoutPrompt(competition, participant) {
                this.$emit('update-participants', this.participants.map(p => {
                    if (p == participant) {
                        p.competitions = p.competitions.filter(c => c != competition);
                    }
                    return p;
                }));

                this.feis.competitions = this.feis.competitions.map(feisCompetition => {
                    if (feisCompetition.id === competition.id) {
                        feisCompetition.entries_left += 1;
                        return feisCompetition;
                    } else {
                        return feisCompetition;
                    }
                });
            },
            removeCompetitionFromCurrentParticipant(competition) {
                this.currentParticipant.competitions = this.currentParticipant.competitions.filter(c => c.id !== competition.id);

                this.feis.competitions = this.feis.competitions.map(feisCompetition => {
                    if (feisCompetition.id === competition.id) {
                        feisCompetition.entries_left += 1;
                        return feisCompetition;
                    } else {
                        return feisCompetition;
                    }
                });
            },
            addCompetition(competition) {
                if (competition.dance_question && competition.dance_question.toLowerCase() !== 'none') {
                    if (!competition.dance_choice) {
                        this.$buefy.dialog.alert(`Please select a dance to add this competition to the basket`);
                        return;
                    }
                }

                const allParticipants = [...this.participants, this.currentParticipant];
                const otherFeisWithEntries = allParticipants
                    .flatMap(participant => participant.competitions)
                    .map(competition => competition.feis_id)
                    .find(feisId => feisId !== this.feis.id);


                if (otherFeisWithEntries) {
                    this.$buefy.dialog.alert({
                        title: 'Please empty your basket',
                        message: 'Sorry, you can only enter one feis at a time &mdash; Please empty your basket to continue.',
                    });
                } else {
                    this.currentParticipant.competitions.push(competition);


                    this.feis.competitions = this.feis.competitions.map(feisCompetition => {
                        if (feisCompetition.id === competition.id) {
                            feisCompetition.entries_left -= 1;
                            return feisCompetition;
                        } else {
                            return feisCompetition;
                        }
                    });
                }
            },
            addAnotherParticipant() {
                if (this.currentParticipant) {
                    this.participants.push(this.currentParticipant);
                }
                this.$emit('edit-current-participant', 'new');
                this.$emit('go-to','participant-details');
            },
            goToBasket() {
                // No-one added
                if (this.participants.length === 0 && !this.currentParticipant.year_of_birth) {

                    if (this.notification) {
                        this.notification = undefined;
                    }
                    this.notification = this.$buefy.notification.open({
                        message: 'Your basket is empty',
                        indefinite: true,
                        queue: false,
                        active: true
                    });
                    return;
                }

                if (this.currentParticipant) {
                  this.participants.push(this.currentParticipant);
                }
                this.$emit('edit-current-participant', 'new');
                this.$emit('go-to', 'basket');
            },
            formatDisplayAge(competition) {

                if (competition.display_age) {
                    return competition.display_age;
                }

                return formatMaxAge(competition.max_age, this.feis.start_date);
            },
            friendlyDate(dateString) {
                return friendlyDate(dateString);
            },
            friendlyMoney(inCents) {
                return friendlyMoney(inCents);
            }
        }
    }
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s ease;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  button.is-text.view-all-button {
    font-size: 0.7em
  }
</style>
