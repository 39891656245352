<template>
  <div class="has-background-light main-container">
    <header>
      <nav class="navbar is-dark has-text-light basket-header">
        <a class="navbar-item has-text-light ml-4" @click="$router.push({ name: 'home'})">
          <strong>Entry Table</strong>
        </a>
        <span class="navbar-end mr-4">
          <a class="navbar-item" @click="goToBasket">
            <span v-if="basketCount > 0" class="basketCount">
              {{ basketCount }}
            </span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 18H4V8H8V10H10V8H14V10H16V8H20V18ZM14 6H10C10 4.89717 10.8972 4 12 4C13.1028 4 14 4.89717 14 6ZM16 6C16 3.79442 14.2056 2 12 2C9.79442 2 8 3.79442 8 6H2V19L3 20H21L22 19V6H16Z" fill="#ffffff"/>
            </svg>
          </a>
        </span>
      </nav>
    </header>
    <div class="section" style="padding-top: 64px">
        <div class="container basket">
          <div class="columns">
            <div class="column">
              <h2 class="title is-2" style="margin-bottom: 40px !important">Basket</h2>
              <div class="box has-text-white is-dark-one">
                <div class="columns">
                  <div class="column ml-5 mt-4 mb-4">
                    <h3 class="title is-4 mb-2 has-text-weight-normal" style="margin-bottom: 2.5em">{{ feis.name }}</h3>
                    <ul class="mt-2">
                      <li>{{ feis.type }}</li>
                      <li><strong v-html="formattedDates"/></li>
                    </ul>
                  </div>
                  <div class="column has-text-right mt-4 mb-4">
                    <a class="bold-hover same-color mr-4" type="is-text has-text-white mr-5" @click="removeFeis">Remove feis from basket</a>
                  </div>
                </div>
              </div>
              <div v-if="participants.length > 0" v-for="(participant, index) in participants" :key="participant.year_of_birth + '||' + participant.name + '||' + feis.name">
                <div style="margin-top: 65px">
                  <h3 class="title is-5 has-text-dark-one">Dancer {{ index + 1}}</h3>
                  <h4 class="subtitle has-text-dark mb-2">{{ participant.name }}</h4>
                  <ul class="mb-4">
                    <li>Year of birth &mdash;{{ participant.year_of_birth }}</li>
                    <li>{{ participant.dancing_school }}</li>
                  </ul>
                  <a type="is-link is-light" @click="editParticipantDetails(participant)">Edit details</a>
                  <a class="ml-6" type="is-link is-light" @click="removeParticipant(participant)">Remove dancer</a>
                </div>
                <template v-if="participant.competitions.length > 0">
                  <b-table
                    class="mt-4"
                    :mobile-cards="false"
                    :data="participant.competitions"
                    :show-header="false"
                    :bordered="false"
                    :striped="true">
                    <b-table-column field="name" v-slot="props">
                      {{ props.row.name }}
                      <p v-if="props.row.dance_choice">
                        <em>{{props.row.dance_choice}}</em>
                      </p>
                    </b-table-column>
                    <b-table-column field="grade" v-slot="props">
                      {{ props.row.grade }}
                    </b-table-column>
                    <b-table-column field="age" centered v-slot="props">
                      {{ formatDisplayAge(props.row) }}
                    </b-table-column>
                    <b-table-column field="price" centered v-slot="props">
                      {{ friendlyMoney(props.row.fee_in_cents) }}
                    </b-table-column>
                    <b-table-column field="cta" label="" centered v-slot="props">
                      <a class="is-size-4 is-link"
                         style="display: block; margin-top: -6px"
                         @click="removeCompetitionWithoutPrompt(props.row, participant)">
                        &times;
                      </a>
                    </b-table-column>
                  </b-table>
                  <div class="section" v-if="feis.results_available_price && participant.competitions.map(competition => competition.id).filter(id => id === -1).length === 0">
                    <b-button type="is-link is-primary is-rounded" @click="purchaseResultsForParticipant(participant)">Purchase results ({{friendlyMoney(feis.results_available_price)}})</b-button>
                    <p class="mt-4 ml-1" v-if="feis.results_note">{{ feis.results_note }}</p>
                  </div>
                </template>
                <div class="section" v-else>
                  <b-button type="is-link is-primary is-rounded" @click="editParticipantCompetitions(participant)">Add competition</b-button>
                </div>
              </div>
              <div class="section">
                <b-button v-if="participants.length > 0" type="is-link is-outlined is-rounded" @click="addNewParticipant">Add new dancer</b-button>
                <b-button v-else type="is-link is-outlined is-rounded" @click="$emit('go-to', 'participant-details')">Add dancers</b-button>
              </div>
              <template v-if="feis.guest_tickets.length > 0">
                <h4 class="title is-5 has-text-dark">Guest Admission</h4>
                <p>(You do not need to purchase a ticket for dancers)</p>
                <div class="mt-4 is-size-5" v-if="guestsErrorMessage">
                  <b-notification type="is-danger is-light" :closable="false">
                    {{ guestsErrorMessage }}
                  </b-notification>
                </div>
                <div class="columns mt-2 is-size-6">
                  <div class="column is-half">
                    <b-field message="Choose your ticket price and then press Add">
                      <b-select v-model="draftTicket.type" placeholder="Choose ticket type">
                        <option :value="null">None</option>
                        <option
                          v-for="ticket in feis.guest_tickets"
                          :value="ticket"
                          :key="ticket.index">
                          {{ ticket.name }} ({{friendlyMoney(ticket.fee_in_cents)}})
                        </option>
                      </b-select>
                    </b-field>
                    <div v-if="draftTicketDescription" class="mt-2 ml-1">
                      {{ draftTicketDescription }}
                    </div>
                  </div>
                  <div class="column is-one-quarter">
                    <b-numberinput type="is-light" :min="1" :step="1" :min-step="1" :editable="false" v-model="draftTicket.quantity"></b-numberinput>
                  </div>
                  <div class="column is-one-quarter">
                    <b-button type="mt-2 mb-4 is-link is-primary is-rounded"
                              :disabled="!draftTicket.type"
                              @click="addTicket">Add</b-button>
                  </div>
                </div>
                <div class="mt-2">
                  <template v-if="tickets.length > 0">
                    <b-table
                      class="mt-4"
                      :mobile-cards="false"
                      :data="tickets"
                      :show-header="false"
                      :bordered="false"
                      :striped="true">
                      <b-table-column field="name" v-slot="props">
                        {{ props.row.type.name }}
                      </b-table-column>
                      <b-table-column field="quantity" v-slot="props">
                        &times; {{ props.row.quantity }}
                      </b-table-column>
                      <b-table-column field="price" centered v-slot="props">
                        {{ friendlyMoney(props.row.type.fee_in_cents * props.row.quantity) }}
                      </b-table-column>
                      <b-table-column field="cta" label="" centered v-slot="props">
                        <a class="is-size-4 is-link"
                           style="display: block; margin-top: -6px"
                           @click="removeTicket(props.row.type)">
                          &times;
                        </a>
                      </b-table-column>
                    </b-table>
                  </template>
                </div>
              </template>
              <div class="mt-6 contact-details" v-if="feis.show_contact_details">
                <h4 class="title is-5 has-text-dark" id="contact_details" style="margin-bottom: 2em">Contact details</h4>
                <p>We will email confirmation of entry to this address. You will also need to show this
                  email to gain entry to the Feis Venue.</p>
                <b-field
                  custom-class="mt-6"
                  label="Name"
                  :type="contactDetailsForm.name.type"
                  :message="contactDetailsForm.name.message"
                >
                  <b-input v-model="contact.name"
                           @input="newValue => this.validate(newValue, 'contact-name', this.contactDetailsForm.hasBeenSubmitted)"
                  ></b-input>
                </b-field>
                <b-field label="Phone"
                         custom-class="mt-6"
                         :type="contactDetailsForm.phone.type"
                         :message="contactDetailsForm.phone.message">
                  <b-input v-model="contact.phone"
                           @input="newValue => this.validate(newValue, 'contact-phone', this.contactDetailsForm.hasBeenSubmitted)"></b-input>
                </b-field>
                <b-field label="Email"
                         custom-class="mt-6 contact-details-email"
                         :type="contactDetailsForm.email.type"
                         :message="contactDetailsForm.email.message">
                  <b-input type="email" v-model="contact.email"
                           @input="newValue => this.validate(newValue, 'contact-email', this.contactDetailsForm.hasBeenSubmitted)"></b-input>
                </b-field>
              </div>
            </div>
            <div class="column is-offset-5pc">
              <div class="section sticky">
                <b-notification v-if="basket && basket.shouldShowMessage" type="is-danger" style="margin-right: 100px; margin-right: 36px">
                  Your payment was cancelled.
                </b-notification>
                <div class="box mt-3 basketSubtotal">
                  <div class="section">
                    <table class="table mb-4 is-size-6">
                      <tr>
                        <th>Competitions price:</th><td class="has-text-right">{{ friendlyMoney(competitionsSubtotal) }}</td>
                      </tr>
                      <tr v-if="accompanyingSubtotal > 0">
                        <th>Guest tickets:</th><td class="has-text-right">{{ friendlyMoney(accompanyingSubtotal) }}</td>
                      </tr>
                      <tr v-if="serviceChargeSubtotal > 0">
                        <th>Service charge:</th><td class="has-text-right">{{ friendlyMoney(serviceChargeSubtotal) }}</td>
                      </tr>
                      <tr>
                        <th class="is-size-4 pt-5"><strong>Total to pay:</strong></th><td class="is-size-4 pt-5 has-text-right">{{ friendlyMoney(total) }}</td>
                      </tr>
                    </table>
                    <div class="field ml-4 mb-4 mt-6" :class="contactDetailsForm.privacy.type">
                      <b-checkbox v-model="hasAcknowledgedPrivacyPolicy"
                                  @input="validate(hasAcknowledgedPrivacyPolicy, 'contact-privacy', contactDetailsForm.hasBeenSubmitted)">
                        <span>I have read and accepted the <a href="/pages/privacy" target="_blank">privacy policy</a>.</span>
                      </b-checkbox>
                    </div>
                    <b-button type="mt-2 mb-4 is-link is-primary is-rounded" :disabled="isPaymentDisabled" @click="submitBasket">Continue to payment</b-button>
                  </div>
                </div>
                <h4 class="title is-5 has-text-dark" v-if="feis.terms">Notes</h4>
                <div class="is-size-6 feis-terms-wrapper" v-if="feis.terms !== ''">
                  <b-notification type="is-info has-text-dark" :closable="false" v-html="addTerms(feis)"/>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
    import {formatMaxAge, friendlyDate, friendlyDateTime} from './date-time';
    import {friendlyMoney, range} from './numbers';
    import axios from "axios";
    import {loadStripe} from '@stripe/stripe-js';
    // TODO: Move API key so loaded via AJAX
    const stripePromise = loadStripe(window.pk);

    export default {
        props: {
            headerImage: {
                required: false,
                type: String,
                default: undefined
            },
            feis: {
                required: false,
                type: Object,
                default: undefined
            },
            dancingSchools: {
                required: true,
                type: Array
            },
            basket: {
                required: false,
                type: Object
            },
            participants: {
                required: true,
                type: Array
            }
        },
        data() {
            return {
                contactDetailsForm: {
                    hasBeenSubmitted: false,
                    name: {
                        type: undefined,
                        message: undefined
                    },
                    phone: {
                        type: undefined,
                        message: undefined
                    },
                    email: {
                        type: undefined,
                        message: undefined
                    },
                    privacy: {
                        type: undefined
                    }
                },
                notification: undefined,
                hasAcknowledgedPrivacyPolicy: false,
                hasAcknowledgedNoGuests: false,
                currentParticipantOldYearOfBirth: undefined,
                tickets: [],
                isParticipantValid: false,
                contact: {
                    name: null,
                    phone: null,
                    email: null
                },
                draftTicket: {
                    type: null,
                    quantity: 1
                }
            }
        },
        mounted() {
            if (!this.feis) {
                console.log('No feis selected, going to home');
                this.$router.replace({name: 'home'});
                return;
            }

            if (this.basket) {
                this.contact = this.basket.contact;
                this.tickets = this.basket.tickets

                axios.post('basket/empty').catch(e => console.error(e));
            }
        },
        computed: {
            draftTicketDescription() {
                return this.draftTicket.type === null ? null : this.draftTicket.type.description;
            },
            isPaymentDisabled() {
                if (this.competitionsSubtotal === 0) {
                    return true;
                }

                if (!this.hasValidNumberOFAccompanyingAdults) {
                    return true;
                }

                return false;
            },
            numberOfGuests() {
                return this.tickets.reduce((subtotal, ticket) => subtotal + ticket.quantity, 0);
            },
            guestsErrorMessage() {
                if (this.hasValidNumberOFAccompanyingAdults) {
                    return;
                }

                return `This feis allows a maximum of ${this.feis.max_accompanying_per_entrant} accompanying guests per entrant. You currently have ${this.numberOfGuests} guest tickets added.`
            },
            filteredDancingSchools() {

                const participantsSchool = this.currentParticipant.dancing_school;

                if (!participantsSchool) {
                    return this.dancingSchools;
                }

                // cf. https://stackoverflow.com/a/25575009/1474421
                const punctuationRegex = /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-.\/:;<=>?@\[\]^_`{|}~]/g;
                const spacesRegex = /\s+/g;

                const normalised = word => word.toLowerCase()
                    .replace(punctuationRegex, '')
                    .replace(spacesRegex, ' ');

                return this.dancingSchools
                    .filter(school => typeof school !== 'undefined' && school !== null)
                    .filter(school => {
                        const availableSchool = normalised(school);
                        const chosenSchool = normalised(participantsSchool);
                        return availableSchool.includes(chosenSchool);
                    });
            },
            basketCount() {
                const competitionCount = this.participants
                    .reduce((subtotal, participant) => {
                        return subtotal + participant.competitions.length;
                    }, 0);
                return competitionCount;
            },
            competitionsSubtotal() {
                return this.participants.reduce((participantSubtotal, participant) => {
                    return participantSubtotal + participant.competitions
                        .reduce((competitionsTotal, competition) => competitionsTotal + competition.fee_in_cents, 0);
                }, 0);
            },
            accompanyingSubtotal() {
                return this.tickets.reduce((subtotal, ticket) => {
                    const thisTicket = ticket.quantity * ticket.type.fee_in_cents;
                    return subtotal + thisTicket;
                }, 0)
            },
            serviceChargeSubtotal() {
                return this.competitionsSubtotal === 0 ? 0 : this.feis.service_charge_in_cents;
            },
            total() {
                return this.competitionsSubtotal + this.accompanyingSubtotal + this.serviceChargeSubtotal;
            },
            hasValidContactDetails() {

                if (!this.feis.show_contact_details) {
                    this.contact.name = null;
                    this.contact.email = null;
                    this.contact.phone = null;
                    return true;
                }

                return this.contact.name != null && this.contact.name.trim() !== '' &&
                    this.contact.phone != null && this.contact.phone.trim() !== '' &&
                    this.contact.email != null && this.contact.email.trim() !== '';
            },
            hasValidNumberOFAccompanyingAdults() {
                return this.numberOfGuests <= (this.feis.max_accompanying_per_entrant * this.participants.length);
            },
            yearsToShown() {
                const currentYear = new Date().getFullYear();
                const youngestAge = 3;
                const oldestAge = 21;
                const ages = range(youngestAge, oldestAge);
                return ages.map(age => currentYear - age);
            },
            formattedDates() {
                if (this.feis.start_date === this.feis.end_date) {
                    // Single day event
                    return `${friendlyDate(this.feis.start_date)}`;
                } else {
                    return `${friendlyDate(this.feis.start_date)} — ${friendlyDate(this.feis.end_date)}`;
                }
            }
        },
        methods: {
            addTicket() {
                if (this.tickets.map(ticket => ticket.type).includes(this.draftTicket.type)) {
                  const matchingTicket = this.tickets.find(ticket => ticket.type === this.draftTicket.type);
                  matchingTicket.quantity += this.draftTicket.quantity;
                } else {
                  this.tickets.push(this.draftTicket);
                }
                this.draftTicket = {
                    type: null,
                    quantity: 1
                }
            },
            removeTicket(ticketType) {
                this.tickets = this.tickets.filter(t => t.type != ticketType);
            },
            addNewParticipant() {
                if (this.currentParticipant) {
                    this.participants.push(this.currentParticipant);
                }
                this.$emit('edit-current-participant', 'new');
                this.$emit('go-to','participant-details');
            },
            addTerms(feis) {
              const setOfTerms = feis.terms.filter(terms => terms.trim() !== '');
              if (setOfTerms.length === 1) {
                  return `<p>${setOfTerms[0].replace(/\n/, '<br/>')}</p>`;
              } else {
                  return `<ul style="list-style: inherit">
                            <li class="mb-2 ml-2">${setOfTerms.join('</li><li class="mb-2 ml-2">')}</li>
                          </ul>`;
              }
            },
            validate(value, field, hasBeenSubmitted) {
                let isValid = false;

                if (value && String(value).trim() !== '') {
                    isValid = true;
                }

                if (!hasBeenSubmitted) {
                    return;
                }

                if (isValid) {
                    switch (field) {
                        case 'contact-name':
                            this.contactDetailsForm.name.type = undefined;
                            this.contactDetailsForm.name.message = undefined;
                            break;
                        case 'contact-phone':
                            this.contactDetailsForm.phone.type = undefined;
                            this.contactDetailsForm.phone.message = undefined;
                            break;
                        case 'contact-email':
                            this.contactDetailsForm.email.type = undefined;
                            this.contactDetailsForm.email.message = undefined;
                            break;
                        case 'contact-privacy':
                            this.contactDetailsForm.privacy.type = undefined;
                            break;
                    }
                } else {
                    switch (field) {
                        case 'contact-name':
                            this.contactDetailsForm.name.type = 'is-danger';
                            this.contactDetailsForm.name.message = 'Contact name is required'
                            break;
                        case 'contact-phone':
                            this.contactDetailsForm.phone.type = 'is-danger';
                            this.contactDetailsForm.phone.message = 'Contact phone number is required';
                            break;
                        case 'contact-email':
                            this.contactDetailsForm.email.type = 'is-danger';
                            this.contactDetailsForm.email.message = 'Contact email address is required';
                            break;
                        case 'contact-privacy':
                            this.contactDetailsForm.privacy.type = 'is-danger';
                            break;
                    }
                }
            },
            friendlyDateTime(time) {
                return friendlyDateTime(time);
            },
            changeParticipant(selectedParticipant) {
                if (selectedParticipant == this.currentParticipant) {
                    this.addAnotherParticipant();
                } else {
                    this.participants.push(this.currentParticipant);
                    this.editParticipantCompetitions(selectedParticipant);
                }
            },
            multiline(string) {
                return string.split(/\n/).join('<br/>');
            },
            removeFeis() {
                this.$buefy.dialog.confirm({
                    title: `Remove all participants`,
                    message: `Are you sure you want to remove all participants for ${this.feis.name}?`,
                    confirmText: `Yes, remove all participants`,
                    cancelText: `Cancel`,
                    type: 'is-warning',
                    hasIcon: false,
                    onConfirm: () => {
                        this.loadingSpinner = this.$buefy.loading.open();
                        this.$emit('edit-current-participant', undefined);
                        this.$emit('update-participants', []);
                        axios.post('basket/empty')
                            .then(async response => {
                                this.$emit('back');

                            })
                            .catch(e => {
                                console.error(e);
                                this.loadingSpinner.close();
                                this.$buefy.dialog.alert(`<p>Error clearing basket. If this problem persists, please <a href="/contact" target="_blank">contact us</a> via our support form.</p>`)
                            })
                            .finally(() => this.loadingSpinner.close());
                    }
                });
            },
            captureExistingYearOfBirth() {
                if (this.currentParticipant.competitions.length > 0) {
                    this.currentParticipantOldYearOfBirth = this.currentParticipant.year_of_birth;
                }
            },
            async submitBasket() {
                this.contactDetailsForm.hasBeenSubmitted = true;
                if (!this.hasAcknowledgedPrivacyPolicy) {
                    this.validate(this.contact.privacy, 'contact-privacy', this.contactDetailsForm.hasBeenSubmitted);
                }

                if (!this.hasValidContactDetails) {
                    document.querySelector('#contact_details').scrollIntoView({
                        behavior: 'smooth'
                    });
                    this.validate(this.contact.name, 'contact-name', this.contactDetailsForm.hasBeenSubmitted)
                    this.validate(this.contact.phone, 'contact-phone', this.contactDetailsForm.hasBeenSubmitted)
                    this.validate(this.contact.email, 'contact-email', this.contactDetailsForm.hasBeenSubmitted)

                }

                if (!this.hasAcknowledgedPrivacyPolicy || !this.hasValidContactDetails) {
                    return
                }

                // Feis doesn't have guest tickets or user has added at least one ticket
                // Disable warning
                if (this.numberOfGuests > 0 || this.feis.guest_tickets.length === 0) {
                    this.hasAcknowledgedNoGuests = true;
                }

                if (!this.hasAcknowledgedNoGuests) {
                    this.$buefy.dialog.confirm({
                        title: `No guest tickets added`,
                        message: `You have not selected an admission ticket, if someone is attending with the dancer you need to add this.<br/>Are you sure you want to continue without any guests?`,
                        confirmText: `Continue without guest`,
                        cancelText: `Go back`,
                        type: 'is-warning',
                        hasIcon: false,
                        onConfirm: () => {
                            this.hasAcknowledgedNoGuests = true;
                        }
                    });
                    return;
                }

                this.loadingSpinner = this.$buefy.loading.open();
                const stripe = await stripePromise;
                const payload = {
                    feis_id: this.feis.id,
                    participants: this.participants.map(participant => {
                        return {
                            ...participant,
                            competitions: participant.competitions.filter(comp => comp.id !== -1)
                        }
                    }),
                    tickets: this.tickets,
                    number_results: this.participants.filter(participant => participant.competitions.map(comp => comp.id).includes(-1)).length,
                    contact: this.contact
                };

                this.contactDetailsForm.hasBeenSubmitted = false;
                axios.post('basket', payload)
                    .then(async response => {
                        this.loadingSpinner.close();
                        const session = response.data;
                        // When the customer clicks on the button, redirect them to Checkout.
                        const result = await stripe.redirectToCheckout({
                            sessionId: session.id,
                        });

                        if (result.error) {
                            this.$buefy.snackbar.open(`Error loading checkout`)
                        }

                    })
                    .catch(e => {
                        console.error(e);
                        this.loadingSpinner.close();
                        this.$buefy.dialog.alert(`<p>Error saving basket. If this problem persists, please <a href="/contact" target="_blank">contact us</a> via our support form.</p>`)
                    });
            },
            editParticipantCompetitions(participant) {
                this.$emit('update-participants', this.participants.filter(p => p != participant));
                this.$emit('edit-current-participant', participant);
                this.$emit('go-to', 'select-competitions');
            },
            editParticipantDetails(participant) {
                this.$emit('update-participants', this.participants.filter(p => p != participant));
                this.$emit('edit-current-participant', participant);
                this.$emit('go-to', 'participant-details');
            },
            removeParticipant(participant) {
                this.$buefy.dialog.confirm({
                    title: `Removing ${participant.name}`,
                    message: `Are you sure you want to remove ${participant.name}? This will also remove all the competitions that they have entered.`,
                    confirmText: `Yes, remove`,
                    cancelText: `Cancel`,
                    type: 'is-warning',
                    hasIcon: false,
                    onConfirm: () => {
                        participant.competitions.forEach(competition => {
                            this.removeCompetitionWithoutPrompt(competition, participant);
                        });

                        this.$emit('update-participants', this.participants.filter(p => p != participant));
                    }
                });
            },
            removeCompetitionWithoutPrompt(competition, participant) {
                this.$emit('update-participants', this.participants.map(p => {
                    if (p == participant) {
                        p.competitions = p.competitions.filter(c => c != competition);
                    }
                    return p;
                }));

                this.feis.competitions = this.feis.competitions.map(feisCompetition => {
                    if (feisCompetition.id === competition.id) {
                        feisCompetition.entries_left += 1;
                        return feisCompetition;
                    } else {
                        return feisCompetition;
                    }
                });
            },
            purchaseResultsForParticipant(participant) {
                this.$emit('update-participants', this.participants.map(p => {
                    if (p == participant) {
                        p.competitions.push({
                            id: -1,
                            name: "Results",
                            fee_in_cents: this.feis.results_available_price
                        });
                    }
                    return p;
                }));
            },
            removeCompetitionFromCurrentParticipant(competition) {
                this.currentParticipant.competitions = this.currentParticipant.competitions.filter(c => c != competition);

                this.feis.competitions = this.feis.competitions.map(feisCompetition => {
                    if (feisCompetition.id === competition.id) {
                        feisCompetition.entries_left += 1;
                        return feisCompetition;
                    } else {
                        return feisCompetition;
                    }
                });

            },
            addCompetition(competition) {
                this.currentParticipant.competitions.push(competition);


                this.feis.competitions = this.feis.competitions.map(feisCompetition => {
                    if (feisCompetition.id === competition.id) {
                        feisCompetition.entries_left -= 1;
                        return feisCompetition;
                    } else {
                        return feisCompetition;
                    }
                });
            },
            addAnotherParticipant() {
                if (this.currentParticipant) {
                    this.participants.push(this.currentParticipant);
                }
                this.$emit('edit-current-participant', 'new');
                this.$emit('go-to','participant-details');
            },
            goToBasket() {
                // No-one added
                if (this.participants.length === 0 && !this.currentParticipant.year_of_birth) {

                    if (this.notification) {
                        this.notification = undefined;
                    }
                    this.notification = this.$buefy.notification.open({
                        message: 'Your basket is empty',
                        indefinite: true,
                        queue: false,
                        active: true
                    });
                    return;
                }

                if (this.currentParticipant) {
                    this.participants.push(this.currentParticipant);
                }
                this.$emit('edit-current-participant', 'new');
                this.$emit('go-to', 'basket');
            },
            formatDisplayAge(competition) {

                if (competition.display_age) {
                    return competition.display_age;
                }

                return formatMaxAge(competition.max_age, this.feis.start_date);
            },
            friendlyDate(dateString) {
                return friendlyDate(dateString);
            },
            friendlyMoney(inCents) {
                return friendlyMoney(inCents);
            }
        }
    }
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s ease;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
