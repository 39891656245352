<template>
  <div class="has-background-light main-container" v-if="resultForFeis || feisNameFromUrl">
    <header>
      <nav class="navbar is-dark has-text-light normal-header">
        <a class="navbar-item has-text-light ml-4" @click="$router.push({ name: 'home'})">
          <strong>Entry Table</strong>
        </a>
      </nav>
    </header>
    <section class="hero is-dark-one details-hero"
             :style="headerImage ? `background-image: url(${headerImage});` : ''">
      <div class="hero-body">
        <div class="container has-text-white">
          <div class="columns">
            <div class="is-one-third is-offset-one-third column">
                <h2 class="title is-2">{{ resultForFeis ? resultForFeis.name : feisNameFromUrl }}</h2>
                <p v-if="resultForFeis" class="is-6 mb-5">{{ resultForFeis.type }}</p>
                <p><strong v-html="formattedDates" /></p>
                <hr/>
                <div class="columns" v-if="resultForFeis">
                  <div class="column">
                    <ul>
                      <li v-html="multiline(resultForFeis.venue)"/>
                    </ul>
                  </div>
                </div>
            </div>
          </div>

        </div>
      </div>
    </section>
      <div class="section select-competitions">
        <main role="main" class="container is-widescreen">
          <h2 class="title is-3 has-text-dark-one has-text-weight-normal" style="margin-bottom: 56px">
            Results
          </h2>
          <div style="margin-top: 70px;" v-if="competitionResults && competitionResults.length > 0">
            <div v-for="comp in competitionResults" :key="`${comp.name}|${comp.grade}|${comp.display_age}`">
              <h3 class="subtitle is-4 mt-6">{{ comp.name }} ({{ comp.grade }}, {{ comp.display_age }})</h3>
              <a v-if="comp.result_file" :href="comp.result_file" class="mb-4 is-link is-light is-primary is-rounded button">
                Download results
              </a>
              <b-table
                v-if="comp.table.length > 0"
                class="mt-4"
                :mobile-cards="false"
                :data="comp.table"
                :bordered="false"
                :striped="true">
                <b-table-column field="dancer_number" label="#" v-slot="props">
                  <p>{{ props.row[0] }}</p>
                </b-table-column>
                <b-table-column field="name" label="Name" v-slot="props">
                  <p><strong>{{ props.row[1] }}</strong></p>
                </b-table-column>
                <b-table-column field="school" label="School" v-slot="props">
                  <p><strong>{{ props.row[2] }}</strong></p>
                </b-table-column>
                <b-table-column field="place" label="Place" v-slot="props">
                  <p><strong>{{ props.row[3] }}</strong></p>
                </b-table-column>
              </b-table>
            </div>
          </div>
          <div v-else>
            <p>Results for this feis are not available yet. Please check back soon.</p>
          </div>
        </main>
      </div>
  </div>
</template>

<script>
    import {friendlyDate, friendlyDateTime} from "./date-time";

    export default {
        props: {
            headerImage: {
                required: false,
                type: String,
                default: undefined
            },
            results: {
                required: false,
                type: Array,
                default: []
            }
        },
        methods: {
            multiline(string) {
                return string.split(/\n/).join('<br/>');
            },
            friendlyDateTime(time) {
                return friendlyDateTime(time);
            }
        },
        computed: {
            feisNameFromUrl() {
                const feisName = this.$route.params.feis_name;
                return feisName
                    .split('-')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
            },
            resultForFeis() {
                if (this.results.length > 0) {
                    const feisName = this.$route.params.feis_name;
                    return this.results.find(result => {
                        const parameterisedResultName = result.name
                                                              .toLowerCase()
                                                              .replace(/ /g, '-');
                        return parameterisedResultName === feisName;
                    });
                } else {
                    return undefined;
                }
            },
            competitionResults() {
                if (!this.resultForFeis) {
                    return [];
                }
                const competitionResults = this.resultForFeis.results;
                return typeof competitionResults === 'undefined' ? [] : competitionResults;
            },
            formattedDates() {
                if (!this.resultForFeis) {
                  return undefined;
                }

                if (this.resultForFeis.start_date === this.resultForFeis.end_date) {
                    // Single day event
                    return `${friendlyDate(this.resultForFeis.start_date)}`;
                } else {
                    return `${friendlyDate(this.resultForFeis.start_date)} — ${friendlyDate(this.resultForFeis.end_date)}`;
                }
            }
        }
    }
</script>
