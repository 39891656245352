import {format, parse} from "date-fns";

export const friendlyDate = dateString => {
    if (dateString === '' || !dateString) {
        return null;
    }
    const date = parse(dateString, "yyyy-MM-dd", new Date());
    return format(date, 'dd MMM');
};


export const friendlyDateTime = dateString => {
    if (dateString === '' || !dateString) {
        return null;
    }
    const date = parse(dateString, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", new Date());
    return format(date, 'hh:mm a, dd MMM');
}

export const formatMaxAge = (firstOfJanInYear, feisStartDate) => {
    const eligibleYearOfBirth = new Date(firstOfJanInYear).getFullYear();
    const yearOfFeis = new Date(feisStartDate).getFullYear();
    let ageBracket = yearOfFeis - eligibleYearOfBirth;
    if (Number.isNaN(ageBracket) || ageBracket < 0) {
        return '';
    }
    return `Under ${ageBracket}`;
}

export const formatMinAge = firstOfJanInYear => {
    const eligibleYearOfBirth = new Date(firstOfJanInYear).getFullYear();
    const currentYear = new Date().getFullYear();
    let ageBracket = currentYear - eligibleYearOfBirth;
    if (Number.isNaN(ageBracket) || ageBracket < 0) {
        return '';
    }
    return `At least ${ageBracket}`;
}
