<template>
  <div class="has-background-light main-container">
    <header>
      <nav class="navbar is-dark has-text-light normal-header">
        <a class="navbar-item has-text-light ml-4" @click="$router.push({ name: 'home'})">
          <strong>Entry Table</strong>
        </a>
        <span class="navbar-end mr-4">
          <a class="navbar-item" @click="goToBasket">
            <span v-if="basketCount > 0" class="basketCount">
              {{ basketCount }}
            </span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 18H4V8H8V10H10V8H14V10H16V8H20V18ZM14 6H10C10 4.89717 10.8972 4 12 4C13.1028 4 14 4.89717 14 6ZM16 6C16 3.79442 14.2056 2 12 2C9.79442 2 8 3.79442 8 6H2V19L3 20H21L22 19V6H16Z" fill="#ffffff"/>
            </svg>
          </a>
        </span>
      </nav>
    </header>
    <section class="hero is-dark-one details-hero"
             :style="headerImage ? `background-image: url(${headerImage});` : ''">
      <div class="hero-body">
        <div class="container has-text-white">
          <div class="columns">
            <div class="is-one-third is-offset-one-third column">
              <h2 class="title is-2">{{ feis.name }}</h2>
              <p class="is-6 mb-5">{{ feis.type }}</p>
              <p><strong v-html="formattedDates" /></p>
              <hr/>
              <div class="columns">
                <div class="column">
                  <ul>
                    <li v-html="multiline(feis.venue)"/>
                  </ul>
                </div>
                <div class="column">
                  <ul>
                    <li class="mb-5">Closing date for entry<br/> {{ friendlyDateTime(feis.closing_date) }} </li>
                    <li><a v-if="feis.syllabus" class="same-color" :href="feis.syllabus.path">Download syllabus
                      <svg class="ml-2" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7 6.66875C11.9423 6.91252 11.9423 7.30623 11.7 7.55L8.35 10.9125C8.12567 11.14 7.8195 11.2681 7.5 11.2681C7.1805 11.2681 6.87433 11.14 6.65 10.9125L3.3 7.56875C3.14258 7.41021 3.0817 7.17974 3.14028 6.96414C3.19887 6.74854 3.36803 6.58058 3.58403 6.52351C3.80004 6.46645 4.03008 6.52896 4.1875 6.6875L6.875 9.375V1.875C6.875 1.52982 7.15482 1.25 7.5 1.25C7.84518 1.25 8.125 1.52982 8.125 1.875V9.40625L10.8125 6.66875C10.9299 6.55043 11.0896 6.48388 11.2563 6.48388C11.4229 6.48388 11.5826 6.55043 11.7 6.66875ZM12.5 12V10C12.5 9.65482 12.7798 9.375 13.125 9.375C13.4702 9.375 13.75 9.65482 13.75 10V12C13.7466 12.9651 12.9651 13.7466 12 13.75H3C2.03493 13.7466 1.25343 12.9651 1.25 12V10C1.25 9.65482 1.52982 9.375 1.875 9.375C2.22018 9.375 2.5 9.65482 2.5 10V12C2.5 12.2761 2.72386 12.5 3 12.5H12C12.2761 12.5 12.5 12.2761 12.5 12Z" fill="black"/>
                        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="1" width="13" height="13">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7 6.66875C11.9423 6.91252 11.9423 7.30623 11.7 7.55L8.35 10.9125C8.12567 11.14 7.8195 11.2681 7.5 11.2681C7.1805 11.2681 6.87433 11.14 6.65 10.9125L3.3 7.56875C3.14258 7.41021 3.0817 7.17974 3.14028 6.96414C3.19887 6.74854 3.36803 6.58058 3.58403 6.52351C3.80004 6.46645 4.03008 6.52896 4.1875 6.6875L6.875 9.375V1.875C6.875 1.52982 7.15482 1.25 7.5 1.25C7.84518 1.25 8.125 1.52982 8.125 1.875V9.40625L10.8125 6.66875C10.9299 6.55043 11.0896 6.48388 11.2563 6.48388C11.4229 6.48388 11.5826 6.55043 11.7 6.66875ZM12.5 12V10C12.5 9.65482 12.7798 9.375 13.125 9.375C13.4702 9.375 13.75 9.65482 13.75 10V12C13.7466 12.9651 12.9651 13.7466 12 13.75H3C2.03493 13.7466 1.25343 12.9651 1.25 12V10C1.25 9.65482 1.52982 9.375 1.875 9.375C2.22018 9.375 2.5 9.65482 2.5 10V12C2.5 12.2761 2.72386 12.5 3 12.5H12C12.2761 12.5 12.5 12.2761 12.5 12Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask0)">
                          <rect width="15" height="15" fill="white"/>
                        </g>
                      </svg>
                    </a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <transition name="fade" mode="out-in">
      <div class="section">
        <div class="container mt-2">
          <div class="columns">
            <div class="column is-one-third is-offset-one-third dancer-dropdowns-form">
              <h2 style="margin-bottom: 32px; font-size: 1.75rem"
                  class="subtitle is-3 pr-2 has-text-dark">Please enter dancer's details to see competitions</h2>
              <b-field label="Name"
                       :type="participantDetailsForm.name.type"
                       :message="participantDetailsForm.name.message">
                <b-input name="name"
                         @blur="validate(currentParticipant.name, 'name', participantDetailsForm.hasBeenSubmitted)"
                         placeholder="Name"
                         expanded
                         v-model="currentParticipant.name"></b-input>
              </b-field>
              <b-field grouped custom-class="">
                <b-field label="Year of birth"
                         custom-class="mt-5"
                         :type="participantDetailsForm.yob.type"
                         :message="participantDetailsForm.yob.message">
                  <b-select @focus="captureExistingYearOfBirth"
                            @blur="warnAboutRemovingCompetitions"
                            placeholder="Select a year"
                            v-model="currentParticipant.year_of_birth">
                    <option :value="year" v-for="year in yearsToShown">{{year}}</option>
                  </b-select>
                </b-field>
                <b-field label="Gender"
                         custom-class="mt-5"
                         :type="participantDetailsForm.gender.type"
                         :message="participantDetailsForm.gender.message">
                  <b-select placeholder="Please select"
                            @blur="validate(currentParticipant.gender, 'gender', participantDetailsForm.hasBeenSubmitted)"
                            v-model="currentParticipant.gender">
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                  </b-select>
                </b-field>
              </b-field>
              <b-field label="Name of Dancing School"
                       custom-class="mt-5"
                       :type="participantDetailsForm.school.type"
                       :message="participantDetailsForm.school.message">
                <b-select @blur="validate(currentParticipant.dancing_school, 'school', participantDetailsForm.hasBeenSubmitted)"
                          placeholder="Select Dancing School"
                          name="dancing_school"
                          v-model="currentParticipant.dancing_school">
                  <option :value="school" v-for="school in this.dancingSchools">{{school}}</option>
                </b-select>
              </b-field>

              <b-field class="mt-6" style="margin-bottom: 112px;"><!-- Label left empty for spacing -->
                <p class="control">
                  <button class="button is-primary is-rounded" @click="addParticipant">See competitions</button>
                </p>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
    import {formatMaxAge, friendlyDate, friendlyDateTime} from './date-time';
    import {friendlyMoney, range} from './numbers';
    import axios from "axios";
    import {loadStripe} from '@stripe/stripe-js';
    // TODO: Move API key so loaded via AJAX
    const stripePromise = loadStripe(window.pk);

    export default {
        props: {
            headerImage: {
                required: false,
                type: String,
                default: undefined
            },
            feis: {
                required: false,
                type: Object,
                default() {
                    return {};
                }
            },
            dancingSchools: {
                required: true,
                type: Array
            },
            basket: {
                required: false,
                type: Object
            },
            participants: {
                required: true,
                type: Array
            },
            currentParticipant: {
                required: false,
                type: Object,
                default: undefined
            }
        },
        data() {
            return {
                participantDetailsForm: {
                    hasBeenSubmitted: false,
                    name: {
                        type: undefined,
                        message: undefined
                    },
                    yob: {
                        type: undefined,
                        message: undefined
                    },
                    school: {
                        type: undefined,
                        message: undefined
                    },
                    gender: {
                        type: undefined,
                        message: undefined
                    }
                },
                contactDetailsForm: {
                    hasBeenSubmitted: false,
                    isAdult: {
                        type: undefined,
                        message: undefined
                    },
                    name: {
                        type: undefined,
                        message: undefined
                    },
                    phone: {
                        type: undefined,
                        message: undefined
                    },
                    email: {
                        type: undefined,
                        message: undefined
                    },
                    privacy: {
                        type: undefined
                    }
                },
                notification: undefined,
                hasAcknowledgedPrivacyPolicy: false,
                currentParticipantOldYearOfBirth: undefined,
                accompanyingFullPriceAdults: 0,
                accompanyingConcessionAdults: 0,
                accompanyingChildren: 0,
                isParticipantValid: false,
                contact: {
                    name: null,
                    phone: null,
                    email: null,
                    isAdult: null
                }
            }
        },
        mounted() {
            if (!this.feis || !this.feis.code) {
                const lastFeis = sessionStorage.getItem('lastSelectedFeis');
                if (lastFeis) {
                    console.log('Found lastSelectedFeis, inflating');
                    this.$emit('rebind', lastFeis);
                } else {
                  console.log('No feis selected, going to home');
                  this.$router.replace({name: 'home'});
                  return;
                }
            }

            if (this.basket) {
                this.contact = this.basket.contact;
                this.accompanyingFullPriceAdults = this.basket.number_of_adult_tickets;
                this.accompanyingConcessionAdults = this.basket.number_of_concession_tickets;
                this.accompanyingChildren = this.basket.num

                axios.post('basket/empty').catch(e => console.error(e));
            }
        },
        computed: {
            isPaymentDisabled() {
                if (this.competitionsSubtotal === 0) {
                    return true;
                }

                return !this.hasValidNumberOFAccompanyingAdults;


            },
            guestsErrorMessage() {
                if (this.hasValidNumberOFAccompanyingAdults) {
                    return;
                }

                return `This feis allows a maximum of ${this.feis.max_accompanying_per_entrant} accompanying adults per entrant. You currently have ${this.accompanyingConcessionAdults + this.accompanyingFullPriceAdults} added (${this.accompanyingFullPriceAdults} full priced tickets and ${this.accompanyingConcessionAdults} concessions).`
            },
            filteredDancingSchools() {

                const participantsSchool = this.currentParticipant.dancing_school;

                if (!participantsSchool) {
                    return this.dancingSchools;
                }

                // cf. https://stackoverflow.com/a/25575009/1474421
                const punctuationRegex = /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-.\/:;<=>?@\[\]^_`{|}~]/g;
                const spacesRegex = /\s+/g;

                const normalised = word => word.toLowerCase()
                                               .replace(punctuationRegex, '')
                                               .replace(spacesRegex, ' ');

                return this.dancingSchools
                          .filter(school => typeof school !== 'undefined' && school !== null)
                          .filter(school => {
                            const availableSchool = normalised(school);
                            const chosenSchool = normalised(participantsSchool);
                            return availableSchool.includes(chosenSchool);
                          });
            },
            basketCount() {
                const competitionsForCurrentParticipant = this.currentParticipant.competitions.length;
                const countOfOtherParticipantsCompetitions = this.participants
                                                                 .reduce((subtotal, participant) => {
                                                                     return subtotal + participant.competitions.length;
                                                                 }, 0);
                return competitionsForCurrentParticipant + countOfOtherParticipantsCompetitions;
            },
            competitionsSubtotal() {
                return this.participants.reduce((participantSubtotal, participant) => {
                    return participantSubtotal + participant.competitions
                        .reduce((competitionsTotal, competition) => competitionsTotal + competition.fee_in_cents, 0);
                }, 0);
            },
            adultsSubtotal(){
               return this.feis
                   ? this.accompanyingFullPriceAdults * this.feis.admission_fee_cents
                   : 0;
            },
            concessionSubtotal() {
                return this.feis
                    ? this.accompanyingConcessionAdults * this.feis.concession_fee_cents
                    : 0;
            },
            childSubtotal() {
                return this.feis
                    ? this.accompanyingChildren * this.feis.child_fee_cents
                    : 0;
            },
            accompanyingSubtotal() {
                return this.adultsSubtotal + this.concessionSubtotal + this.childSubtotal;
            },
            serviceChargeSubtotal() {
                return this.competitionsSubtotal === 0 || !this.feis
                        ? 0
                        : this.feis.service_charge_in_cents;
            },
            total() {
                return this.competitionsSubtotal + this.accompanyingSubtotal + this.serviceChargeSubtotal;
            },
            hasValidContactDetails() {

                if (!this.feis || !this.feis.show_contact_details) {
                    this.contact.name = null;
                    this.contact.email = null;
                    this.contact.phone = null;
                    this.contact.isAdult = false;
                    return true;
                }

                if (this.contact.isAdult && this.accompanyingFullPriceAdults === 0 && this.accompanyingConcessionAdults === 0) {
                    return false;
                }

                return this.contact.name != null && this.contact.name.trim() !== '' &&
                    this.contact.phone != null && this.contact.phone.trim() !== '' &&
                    this.contact.email != null && this.contact.email.trim() !== '' &&
                    this.contact.isAdult != null && this.contact.isAdult !== '';
            },
            hasValidNumberOFAccompanyingAdults() {
                const totalRequested = this.accompanyingConcessionAdults + this.accompanyingFullPriceAdults;
                return this.feis && totalRequested <= (this.feis.max_accompanying_per_entrant * this.participants.length);


            },
            yearsToShown() {
                const currentYear = new Date().getFullYear();
                const youngestAge = 3;
                const oldestAge = 51;
                const ages = range(youngestAge, oldestAge);
                return ages.map(age => currentYear - age);
            },
            formattedDates() {
                if (!this.feis) {
                    return '';
                }

                if (this.feis.start_date === this.feis.end_date) {
                    // Single day event
                    return `${friendlyDate(this.feis.start_date)}`;
                } else {
                    return `${friendlyDate(this.feis.start_date)} — ${friendlyDate(this.feis.end_date)}`;
                }
            }
        },
        methods: {
            validate(value, field, hasBeenSubmitted) {
                let isValid = false;

                if (value && String(value).trim() !== '') {
                    isValid = true;
                }

                if (!hasBeenSubmitted) {
                    return;
                }

                if (isValid) {
                    switch (field) {
                        case 'name':
                            this.participantDetailsForm.name.type = undefined;
                            this.participantDetailsForm.name.message = undefined;
                            break;
                        case 'yob':
                            this.participantDetailsForm.yob.type = undefined;
                            this.participantDetailsForm.yob.message = undefined;
                            break;
                        case 'school':
                            this.participantDetailsForm.school.type = undefined;
                            this.participantDetailsForm.school.message = undefined;
                            break;
                        case 'gender':
                            this.participantDetailsForm.gender.type = undefined;
                            this.participantDetailsForm.gender.message = undefined;
                            break;
                    }
                } else {
                    switch (field) {
                        case 'name':
                            this.participantDetailsForm.name.type = 'is-danger';
                            this.participantDetailsForm.name.message = 'Dancer name is required';
                            break;
                        case 'yob':
                            this.participantDetailsForm.yob.type = 'is-danger';
                            this.participantDetailsForm.yob.message = 'Year of birth is required';
                            break;
                        case 'school':
                            this.participantDetailsForm.school.type = 'is-danger';
                            this.participantDetailsForm.school.message = 'Dancing school is required';
                            break;
                        case 'gender':
                            this.participantDetailsForm.gender.type = 'is-danger';
                            this.participantDetailsForm.gender.message = 'Gender is required';
                    }
                }
            },
            friendlyDateTime(time) {
              return friendlyDateTime(time);
            },
            changeParticipant(selectedParticipant) {
                if (selectedParticipant == this.currentParticipant) {
                    this.addAnotherParticipant();
                } else {
                    this.participants.push(this.currentParticipant);
                    this.editParticipantCompetitions(selectedParticipant);
                }
            },
            multiline(string) {
              return string ? string.split(/\n/).join('<br/>') : '';
            },
            captureExistingYearOfBirth() {
                if (this.currentParticipant.competitions.length > 0) {
                    this.currentParticipantOldYearOfBirth = this.currentParticipant.year_of_birth;
                }
            },
            warnAboutRemovingCompetitions() {
                if (this.currentParticipant.competitions.length > 0
                && this.currentParticipant.year_of_birth !== this.currentParticipantOldYearOfBirth) {
                    this.$buefy.dialog.confirm({
                        title: `Changing ${this.currentParticipant.name}'s year of birth`,
                        message: `You have already added competitions for ${this.currentParticipant.name}. Changing their year of birth will remove these from the basket. Do you want to continue?`,
                        confirmText: `Yes, remove competitions`,
                        cancelText: `Cancel`,
                        type: 'is-warning',
                        hasIcon: false,
                        onConfirm: () => {
                            this.$emit('update-participants', this.participants.filter(p => p != this.currentParticipant));
                            this.currentParticipant.competitions = [];
                        },
                        onCancel: () => {
                            this.currentParticipant.year_of_birth = this.currentParticipantOldYearOfBirth;
                            this.currentParticipantOldYearOfBirth = undefined;
                        }
                    });
                } else {
                    this.validate(this.currentParticipant.year_of_birth, 'yob', this.participantDetailsForm.hasBeenSubmitted)
                }
            },
            editParticipantCompetitions(participant) {
                this.$emit('update-participants', this.participants.filter(p => p != participant));
                this.$emit('edit-current-participant', participant);
                this.$emit('go-to', 'select-competitions');
            },
            editParticipantDetails(participant) {
              this.$emit('update-participants', this.participants.filter(p => p != participant));
              this.$emit('edit-current-participant', participant);
              this.$emit('go-to', 'participant-details');
            },
            removeParticipant(participant) {
                this.$buefy.dialog.confirm({
                    title: `Removing ${participant.name}`,
                    message: `Are you sure you want to remove ${participant.name}? This will also remove all the competitions that they have entered.`,
                    confirmText: `Yes, remove`,
                    cancelText: `Cancel`,
                    type: 'is-warning',
                    hasIcon: false,
                    onConfirm: () => {
                        participant.competitions.forEach(competition => {
                            this.removeCompetitionWithoutPrompt(competition, participant);
                        });

                        this.$emit('update-participants', this.participants.filter(p => p != participant));
                    }
                });
            },
            removeCompetitionWithoutPrompt(competition, participant) {
                this.$emit('update-participants', this.participants.map(p => {
                    if (p == participant) {
                        p.competitions = p.competitions.filter(c => c != competition);
                    }
                    return p;
                }));

                this.feis.competitions = this.feis.competitions.map(feisCompetition => {
                    if (feisCompetition.id === competition.id) {
                        feisCompetition.entries_left += 1;
                        return feisCompetition;
                    } else {
                        return feisCompetition;
                    }
                });
            },
            removeCompetitionFromCurrentParticipant(competition) {
                this.currentParticipant.competitions = this.currentParticipant.competitions.filter(c => c != competition);

                this.feis.competitions = this.feis.competitions.map(feisCompetition => {
                    if (feisCompetition.id === competition.id) {
                        feisCompetition.entries_left += 1;
                        return feisCompetition;
                    } else {
                        return feisCompetition;
                    }
                });

            },
            addCompetition(competition) {
                this.currentParticipant.competitions.push(competition);


                this.feis.competitions = this.feis.competitions.map(feisCompetition => {
                    if (feisCompetition.id === competition.id) {
                        feisCompetition.entries_left -= 1;
                        return feisCompetition;
                    } else {
                        return feisCompetition;
                    }
                });
            },
            addAnotherParticipant() {
                if (this.currentParticipant) {
                    this.participants.push(this.currentParticipant);
                }
                this.$emit('edit-current-participant', 'new');
                this.$emit('go-to','participant-details');
            },
            goToBasket() {
                // No-one added
                if (this.participants.length === 0 && !this.currentParticipant.year_of_birth) {

                    if (this.notification) {
                        this.notification = undefined;
                    }
                    this.notification = this.$buefy.notification.open({
                        message: 'Your basket is empty',
                        indefinite: true,
                        queue: false,
                        active: true
                    });
                    return;
                }

                if (this.currentParticipant) {
                  this.participants.push(this.currentParticipant);
                }
                this.$emit('edit-current-participant', 'new');
                this.$emit('go-to', 'basket');
            },
            formatDisplayAge(competition) {

                if (competition.display_age) {
                    return competition.display_age;
                }

                return formatMaxAge(competition.max_age, this.feis.start_date);
            },
            friendlyDate(dateString) {
                return friendlyDate(dateString);
            },
            friendlyMoney(inCents) {
                return friendlyMoney(inCents);
            },
            validateParticipant(currentParticipant) {
                const name = currentParticipant.name;
                const yob = currentParticipant.year_of_birth;
                const school = currentParticipant.dancing_school;
                const gender = currentParticipant.gender;

                if (!name || name.trim() === '') {
                    this.participantDetailsForm.name.type = 'is-danger';
                    this.participantDetailsForm.name.message = 'Dancer name is required';
                }

                if (!yob || Number.isNaN(yob)) {
                    this.participantDetailsForm.yob.type = 'is-danger';
                    this.participantDetailsForm.yob.message = 'Year of birth is required';
                }

                if (!school || school.trim() === '') {
                    this.participantDetailsForm.school.type = 'is-danger';
                    this.participantDetailsForm.school.message = 'Dancing school is required';
                }


                if (!gender || gender.trim() === '') {
                    this.participantDetailsForm.gender.type = 'is-danger';
                    this.participantDetailsForm.gender.message = 'Gender is required';
                }

                return name && yob && school && gender;
            },
            addParticipant() {
                if (this.validateParticipant(this.currentParticipant)) {
                    this.$emit('go-to', 'select-competitions');
                    this.participantDetailsForm.name.type = undefined;
                    this.participantDetailsForm.name.message = undefined;
                    this.participantDetailsForm.yob.type = undefined;
                    this.participantDetailsForm.yob.message = undefined;
                    this.participantDetailsForm.school.type = undefined;
                    this.participantDetailsForm.school.message = undefined;
                    this.participantDetailsForm.hasBeenSubmitted = false;
                } else {
                    this.participantDetailsForm.hasBeenSubmitted = true;
                }
            }
        }
    }
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s ease;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
