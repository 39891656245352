<template>
  <keep-alive>
    <router-view
      :dancing-schools="dancingSchools"
      :feis="currentFeis"
      :feiseanna="feiseanna"
      :header-image="headerImage"
      :home_image="home_image"
      :participants="participants"
      :current-participant="currentParticipant"
      :basket="basket"
      :code="selectedCode"
      :results="results"
      @update-participants="updateParticipants"
      @edit-current-participant="editCurrentParticipant"
      @go-to="goToCaptureDetailsState"
      @back="clickedBack"
      @go-to-basket="goToBasket"
      @enter="clickedEnter"
      @result="loadResults"
      @restore-basket="restoreBasket"
      @rebind="rebindFeis"
    />
  </keep-alive>
</template>
<script>
    import axios from "axios";

    const makeDraftParticipant = () => ({
        name: undefined,
        year_of_birth: undefined,
        dancing_school: undefined,
        gender: undefined,
        competitions: [],
        home_image: undefined
    });

    export default {
        data() {
            return {
                selectedCode: undefined,
                feiseanna: [],
                home_image: undefined,
                currentFeis: undefined,
                dancingSchools: [],
                basket: undefined,
                participants: [],
                headerImage: undefined,
                messages: {
                    alert: undefined,
                    notice: undefined
                },
                currentParticipant: makeDraftParticipant(),
                results: []
            }
        },
        methods: {
            clickedBack() {
                console.log('clickedBack called')
                this.basket = undefined;
                this.$router.push({ name: 'home'});
            },
            goToCaptureDetailsState(state) {
                console.log('goToCaptureDetailsState called')
                switch (state) {
                    case 'select-competitions':
                        this.$router.push({name: 'competitions', params: { feis_name: this.currentFeis.name.toLowerCase().replace(/ /g, '-') } });
                        break;
                    case 'participant-details':
                        this.$router.push({name: 'feis', params: { feis_name: this.currentFeis.name.toLowerCase().replace(/ /g, '-') } });
                        break;
                    case 'basket':
                        this.$router.push({name: 'basket'});
                        break;
                    default:
                        this.$router.push({name: 'home'});
                        break;
                }
                this.$forceUpdate();
            },
            goToBasket() {
              console.log('goToBasket called');
              this.$router.push({ name: 'basket'});
            },
            clickedEnter(feis) {
                this.currentFeis = feis;
                sessionStorage.setItem('lastSelectedFeis', JSON.stringify(feis));
                this.$router.push({ name: 'feis', params: { feis_name: this.currentFeis.name.toLowerCase().replace(/ /g, '-') } })

            },
            loadResults(result) {
                this.$router.push({ name: 'results', params: { feis_name: result.name.toLowerCase().replace(/ /g, '-') } })

            },
            restoreBasket(feis, dancingSchools, basket) {
                this.currentFeis = feis;
                this.dancingSchools = dancingSchools;
                this.basket = basket;
                this.participants = this.basket.participants;
                console.log('restoreBasket called');
                this.$router.push({ name: 'basket'});
            },
            updateParticipants(newParticipants) {
                this.participants = newParticipants;
            },
            editCurrentParticipant(nominatedNextParticipant) {
                if (typeof nominatedNextParticipant === 'string' && nominatedNextParticipant === 'new') {
                    this.currentParticipant = makeDraftParticipant();
                } else {
                    this.currentParticipant = nominatedNextParticipant;
                }
            },
            rebindFeis(feisFromSessionStorage) {
                this.currentFeis = JSON.parse(feisFromSessionStorage);
            }
        },
        created() {
            axios.interceptors.request.use(
                config => {
                    config.headers['X-CSRF-Token'] = document.querySelector('meta[name=csrf-token]').content;
                    return config;
                },
                error => Promise.reject(error));
        },

        watch: {
            $route(to, from) {
                window.scrollTo(0, 0);
                if (from.name === 'basket' && to.name !== 'feis') {
                    // Hitting competition page when current participant is undefined.
                    if (this.participants.length > 0 && !this.currentParticipant.name) {
                        this.currentParticipant = this.participants.pop();
                    }

                    if (this.basket) {
                        // Leaving 'basket' page, so remove 'Payment' cancelled message
                        this.basket.shouldShowMessage = false;
                    }
                }

                if ((to.name === 'basket') && this.currentParticipant.name) {
                    this.participants.push(this.currentParticipant);
                    this.currentParticipant = makeDraftParticipant();
                }

                this.$forceUpdate();
            }
        },
        beforeRouteUpdate(to, from, next) {
            console.log(`BeforeRouteUpdate From: ${from.name}; To: ${to.name}`);
            const isGoingToBasket = to.name === 'basket';
            if (isGoingToBasket) {
                if (!this.currentFeis) {
                    this.notification = this.$buefy.notification.open({
                        message: 'Your basket is empty',
                        indefinite: true,
                        queue: false,
                        active: true
                    });
                } else {
                    next();
                }
            } else {
                next();
            }
        },
        mounted() {
            if (this.$route.path === '/') {
                this.$router.push({ name: 'home'});
            }

            axios
                .get('/home.json')
                .then(response => {
                    const payload = response.data;
                    this.home_image = payload.home_image;
                    this.headerImage = payload.header_image;
                    this.feiseanna = payload.feiseanna;
                    this.matchingFeiseanna = payload.feiseanna;
                    this.dancingSchools = payload.dancing_schools;
                    this.basket = payload.basket;
                    this.results = payload.results;

                    if (this.basket) {
                        this.basket.shouldShowMessage = true;
                        this.matchingFeiseanna = this.feiseanna.filter(feis => feis.id == this.basket.feis_id);
                        this.restoreBasket(this.matchingFeiseanna[0], this.dancingSchools, this.basket);
                        return;
                    }

                    const queryString = window.location.search.split('?code=');
                    if (queryString.length === 2) {
                        const possibleCode = queryString[1];
                        this.code = possibleCode;
                        this.findMatching();
                    }
                });

            this.messages.alert = window.messages.alert;
            this.messages.notice = window.messages.notice;

            if (this.messages.notice) {
                this.$buefy.notification.open({
                    message: this.messages.notice,
                    type: 'is-success'
                })
            }

            if (this.messages.alert) {
                this.$buefy.notification.open({
                    message: this.messages.alert,
                    type: 'is-warning'
                })
            }
            this.$forceUpdate();
        }
    }
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s ease;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
