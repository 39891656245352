
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import App from '../app.vue';
import Basket from '../basket.vue';
import Buefy from 'buefy';
import Results from '../results.vue';
import CompetitionDetails from '../competition-details.vue';
import { Integrations } from '@sentry/tracing';
import ParticipantDetails from '../participant-details.vue';
import Router from 'vue-router';
import Search from '../search.vue';
import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue';

const router = new Router({
  routes: [
    {
      path: '/search',
      name: 'home',
      component: Search
    },
    {
      path: '/basket',
      name: 'basket',
      component: Basket
    },
    {
      path: '/:feis_name',
      name: 'feis',
      component: ParticipantDetails
    },
    {
      path: '/:feis_name/competitions',
      name: 'competitions',
      component: CompetitionDetails
    },
    {
      path: '/:feis_name/results',
      name: 'results',
      component: Results
    }
  ]
});

Sentry.init({
  dsn: "https://b96d5c1dc55b465abf1c8f06473b5948@o479100.ingest.sentry.io/5523098",
  integrations: [
    new VueIntegration({
      Vue,
      tracing: true,
      tracingOptions: {
        trackComponents: true,
      },
    }),
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

Vue.use(Buefy)
Vue.use(Router)
Vue.use(TurbolinksAdapter)

if( document.readyState !== 'loading' ) {
  load();
} else {
  document.addEventListener('DOMContentLoaded', function () {
    load();
  });
}

function load() {
  const el = document.getElementById('homePage');
  new Vue({
    el,
    render: h => h(App),
    router: router
  })
}
