<template>
  <div class="home">
    <header>
      <nav class="navbar" style="background: inherit">
          <span class="navbar-end mr-4">
          <a class="navbar-item" @click="goToBasket">
            <span v-if="basketCount > 0" class="basketCount">
              {{ basketCount }}
            </span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 18H4V8H8V10H10V8H14V10H16V8H20V18ZM14 6H10C10 4.89717 10.8972 4 12 4C13.1028 4 14 4.89717 14 6ZM16 6C16 3.79442 14.2056 2 12 2C9.79442 2 8 3.79442 8 6H2V19L3 20H21L22 19V6H16Z" fill="#003170"/>
            </svg>
          </a>
        </span>
      </nav>
    </header>
    <div class="main-container">
      <section class="section main-home-hero" style="position: relative">
        <div class="container">
          <div class="columns">
            <div class="column is-one-third">
              <h1 class="title is-1 main-heading">
                Entry Table
              </h1>
              <h2 class="subtitle is-2 tagline">
                Online entry for Irish Dancing Feiseanna.
              </h2>
              <div class="container">
                <h2 class="is-size-6 ml-1 feis-code-heading">Please enter the Feis code</h2>
                <div class="field has-addons mt-2">
                  <div class="control">
                    <b-field>
                      <b-input placeholder="Search"
                               v-model="code"
                               type="text"
                               custom-class="is-rounded"
                               icon-right="magnify"
                               icon-right-clickable
                               @keyup.native.enter="findMatching"
                               @icon-right-click="findMatching">
                      </b-input>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-offset-one-quarter">
                <img class="main-image" :src="home_image" alt="" width="540px" height="540px" />
            </div>
          </div>
        </div>
        <div class="is-centered" style="position: absolute; bottom: 9px; left: 50%;">
          <svg width="31" height="40" viewBox="0 0 31 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.2381 21.0761C14.5686 21.0784 13.9265 20.8106 13.4571 20.3332L5.99048 12.7808C5.61703 12.4074 5.61703 11.8019 5.99048 11.4285C6.36393 11.055 6.96941 11.055 7.34286 11.4285L14.819 18.9808C14.9298 19.0992 15.0856 19.165 15.2476 19.1618V19.1618C15.4088 19.1605 15.5629 19.0954 15.6762 18.9808L23.1333 11.4285C23.5042 11.055 24.1075 11.0529 24.481 11.4237C24.8544 11.7945 24.8565 12.3979 24.4857 12.7713L17.0381 20.3237C16.564 20.7998 15.9195 21.0672 15.2476 21.0666L15.2381 21.0761Z" fill="black"/>
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="11" width="20" height="11">
              <path d="M15.2381 21.0761C14.5686 21.0784 13.9265 20.8106 13.4571 20.3332L5.99048 12.7808C5.61703 12.4074 5.61703 11.8019 5.99048 11.4285C6.36393 11.055 6.96941 11.055 7.34286 11.4285L14.819 18.9808C14.9298 19.0992 15.0856 19.165 15.2476 19.1618V19.1618C15.4088 19.1605 15.5629 19.0954 15.6762 18.9808L23.1333 11.4285C23.5042 11.055 24.1075 11.0529 24.481 11.4237C24.8544 11.7945 24.8565 12.3979 24.4857 12.7713L17.0381 20.3237C16.564 20.7998 15.9195 21.0672 15.2476 21.0666L15.2381 21.0761Z" fill="white"/>
            </mask>
            <g mask="url(#mask0)">
              <rect width="30.4762" height="30.4762" fill="#0a0f2f"/>
            </g>
            <path d="M15.2381 30.6C14.5686 30.6023 13.9265 30.3345 13.4571 29.8571L5.99048 22.3048C5.61703 21.9313 5.61703 21.3258 5.99048 20.9524C6.36393 20.5789 6.96941 20.5789 7.34286 20.9524L14.819 28.5048C14.9298 28.6231 15.0856 28.6889 15.2476 28.6857V28.6857C15.4088 28.6844 15.5629 28.6194 15.6762 28.5048L23.1333 20.9524C23.5042 20.5789 24.1075 20.5768 24.481 20.9476C24.8544 21.3184 24.8565 21.9218 24.4857 22.2952L17.0381 29.8476C16.564 30.3237 15.9195 30.5911 15.2476 30.5905L15.2381 30.6Z" fill="black"/>
            <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="20" width="20" height="11">
              <path d="M15.2381 30.6C14.5686 30.6023 13.9265 30.3345 13.4571 29.8571L5.99048 22.3048C5.61703 21.9313 5.61703 21.3258 5.99048 20.9524C6.36393 20.5789 6.96941 20.5789 7.34286 20.9524L14.819 28.5048C14.9298 28.6231 15.0856 28.6889 15.2476 28.6857V28.6857C15.4088 28.6844 15.5629 28.6194 15.6762 28.5048L23.1333 20.9524C23.5042 20.5789 24.1075 20.5768 24.481 20.9476C24.8544 21.3184 24.8565 21.9218 24.4857 22.2952L17.0381 29.8476C16.564 30.3237 15.9195 30.5911 15.2476 30.5905L15.2381 30.6Z" fill="white"/>
            </mask>
            <g mask="url(#mask1)">
              <rect y="9.52393" width="30.4762" height="30.4762" fill="#0a0f2f"/>
            </g>
          </svg>

        </div>

      </section>
      <div class="enter-feis-section enter-feis-section section pt-4" style=" min-height: 450px; background: #0A0F2F;">
        <main role="main" class="container is-widescreen">
          <h2 class="subtitle is-size-2 mt-6 has-text-white is-centered" style="margin-top: 1.75em !important">Upcoming events</h2>
          <div class="columns mt-1" style="flex-wrap: wrap;" v-if="matchingFeiseanna.length !== 0">
            <div class="column is-one-third" v-for="feis in matchingFeiseanna" :key="feis.code">
              <div class="card mt-4" style="background: #181E3E">
                <div class="card-content">
                  <h3 class="title is-5 mb-1 has-text-white" style="font-weight: 700">{{ feis.name }}</h3>
                  <p class="subtitle is-6 mt-2 has-text-white" style="margin-top: 4em; margin-bottom: 0.25em;">{{ feis.type }}</p>
                  <strong><time class="has-text-white" :datetime="feis.start_date">{{ formattedDates(feis) }}</time></strong>
                  <div class="field" style="margin-top: 40px">
                    <p class="control">
                      <b-button type="is-primary is-rounded" @click="$emit('enter', feis)">View this feis</b-button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="columns mt-1">
            <div class="column is-half is-offset-one-quarter">
              <div class="card mt-4" style="background: #181E3E">
                <div class="card-content" v-if="code">
                  <h3 class="title is-5 mb-1 has-text-white" style="font-weight: 700">No matches</h3>
                  <p class="subtitle is-6 mt-2 has-text-white">We didn't find any feiseanna for the code <code>{{code}}</code>. <a class="has-text-white" @click="viewAll">View all</a></p>
                </div>
                <div class="card-content" v-else>
                  <h3 class="title is-4 has-text-white">No upcoming feiseanna</h3>
                  <p class="subtitle is-6 has-text-white">There are currently no feiseanna accepting entries at the moment. Please check back with us soon.</p>
                </div>
              </div>
            </div>
          </div>
          <template v-if="results.length > 0">
            <h2 class="subtitle is-size-2 mt-6 has-text-white is-centered" style="margin-top: 1.75em !important">Past feiseanna</h2>
            <div class="columns mt-1" style="flex-wrap: wrap;">
              <div class="column is-one-third" v-for="result in results" :key="result.id">
                <div class="card mt-4" style="background: #181E3E">
                  <div class="card-content">
                    <h3 class="title is-5 mb-1 has-text-white" style="font-weight: 700">{{ result.name }}</h3>
                    <p class="subtitle is-6 mt-2 has-text-white" style="margin-top: 4em; margin-bottom: 0.25em;">{{ result.type }}</p>
                    <strong><time class="has-text-white" :datetime="result.start_date">{{ formattedDates(result) }}</time></strong>
                    <div class="field" style="margin-top: 40px">
                      <p class="control">
                        <b-button type="is-primary is-rounded" @click="$emit('result', result)">View results</b-button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
    import { friendlyDate } from './date-time';
    export default {
        props: {
            participants: {
                required: true,
                type: Array
            },
            currentParticipant: {
                required: false,
                type: Object,
                default: undefined
            },
            home_image: {
                type: String,
                required: false,
                default: undefined
            },
            feiseanna: {
                type: Array,
                required: false,
                default: () => []
            },
            results: {
                type: Array,
                required: false,
                default: () => []
            }
        },
        data: function () {
            return {
                code: undefined,
                feis: undefined,
                matchingFeiseanna: []
            }
        },
        methods: {
            goToBasket() {
                // No-one added
                if (this.basketCount === 0) {
                    if (this.notification) {
                        this.notification = undefined;
                    }
                    this.notification = this.$buefy.notification.open({
                        message: 'Your basket is empty',
                        indefinite: true,
                        queue: false,
                        active: true
                    });
                    return;
                }

                if (this.currentParticipant) {
                    this.participants.push(this.currentParticipant);
                }
                this.$emit('edit-current-participant', 'new');
                this.$emit('go-to', 'basket');
            },
            viewAll() {
                this.code = '';
                this.matchingFeiseanna = this.feiseanna;
            },
            findMatching() {
                if (!this.code || this.code === '') {
                    this.matchingFeiseanna = this.feiseanna;
                } else {
                    const firstMatch = this.feiseanna.find(feis => feis.code.includes(this.code));
                    if (firstMatch) {
                        this.$emit('enter', firstMatch);
                    }
                }
            },
            friendlyDate(dateString) {
                return friendlyDate(dateString);
            },
            formattedDates(feis) {
                if (feis.start_date === feis.end_date) {
                    // Single day event
                    return `${friendlyDate(feis.start_date)}`;
                } else {
                    return `${friendlyDate(feis.start_date)} — ${friendlyDate(feis.end_date)}`;
                }
            }
        },
        watch: {
            feiseanna(newValue, oldValue) {
                this.viewAll();
            }
        },
        computed: {
            basketCount() {
                const competitionsForCurrentParticipant = this.currentParticipant.competitions.length;
                const countOfOtherParticipantsCompetitions = this.participants
                    .reduce((subtotal, participant) => {
                        return subtotal + participant.competitions.length;
                    }, 0);
                return competitionsForCurrentParticipant + countOfOtherParticipantsCompetitions;
            }
        }
    }
</script>
